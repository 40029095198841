import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import { DashboardHeader } from "../../components";
import historyImage from "../../../../asset/history Image.png";
import {
  LoanModal,
  PendingLoanModal,
  WithdrawalModal,
} from "../../components/Modal";
import { useGlobalContext } from "../../../../context/context";
import { CgUnavailable } from "react-icons/cg";

import moment from "moment/moment";

function Loan() {
  const [loanModal, setLoanModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [loanPendingModal, setloanPendingModal] = useState(false);
  const loans = JSON.parse(sessionStorage.getItem("loans"));
  const loantransfers = JSON.parse(sessionStorage.getItem("loanwithdraw"));
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const {
    getAllLoans,
    loanHistory,
    userDetails,
    getUser,
    moneyFormatter,
    geKYCDetails,
    getAllWithdrawals,
  } = useGlobalContext();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const { _id, country } = user;
  const { symbol } = country;

  const toggleWithdrawModal = () => setWithdrawModal(!withdrawModal);
  const toggleLoanModal = () => {
    if (loanHistory === undefined) {
      setLoanModal(!loanModal);
    } else {
      toggleLoanPendingModal();
    }
  };

  const totalAmount = loans - loantransfers;

  const toggleLoanPendingModal = () => setloanPendingModal(!loanPendingModal);

  useEffect(() => {
    getAllLoans(accessToken, _id);
    getUser(accessToken, _id);
    geKYCDetails(accessToken, _id);
    getAllWithdrawals(accessToken, _id);
  }, []);

  return (
    <Fragment>
      <section className="loan_wrapper">
        <DashboardHeader
          title={"Loan"}
          text={"Let's see how well you're doing."}
        />
        <div className="loan_total_balance_sect">
          <div className="history_sect">
            <p className="statement_text">Total Balance</p>
            <h1>
              {symbol}
              {moneyFormatter.format(totalAmount)}
            </h1>
            <button className="button" onClick={toggleWithdrawModal}>
              Withdraw
            </button>
          </div>
          <div className="loan_get_started_wrapper">
            <p className="date_text">GET STARTED WITH Ogidi Finance</p>
            <div>
              <h1>Get Loan Now</h1>
              <p>Click box to apply for loan</p>
              <button className="button" onClick={toggleLoanModal}>
                Apply for Loan
              </button>
            </div>
          </div>
        </div>
        {loanHistory !== undefined ? (
          <div className="history_sect">
            <div className="history_sect_item" id="history_sect_item">
              <div>
                <img src={historyImage} alt="historyimage" />
                <div>
                  <p className="statement_text">
                    {loanHistory.status === "Pending"
                      ? "Processing"
                      : "Approved"}
                  </p>
                  <p className="date_text">
                    {moment(loanHistory.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                </div>
              </div>

              <p className="date_text">
                {symbol}
                {moneyFormatter.format(loanHistory.amount)}
              </p>
            </div>
          </div>
        ) : (
          <div className="history_sect">
            <div className="history_sect_item empty_wrapper">
              <CgUnavailable size={50} color="#e2e8f0" />
            </div>
          </div>
        )}
      </section>
      {withdrawModal && (
        <WithdrawalModal
          toggleWithdrawModal={toggleWithdrawModal}
          totalAmount={totalAmount}
          loanHistory={loanHistory}
          withdrawalPath={"loan"}
        />
      )}
      {loanModal && (
        <LoanModal
          toggleLoanModal={toggleLoanModal}
          toggleLoanPendingModal={toggleLoanPendingModal}
        />
      )}
      {loanPendingModal && (
        <PendingLoanModal
          togglePendingLoanModal={toggleLoanPendingModal}
          toggleLoanModal={toggleLoanModal}
          loanStatus={loanHistory?.status}
        />
      )}
    </Fragment>
  );
}

export default Loan;
