import React from "react";
import { Button, Footer, Header, PageHero } from "../../components";
import "./styles.css";
import { Motion, MotionRight, MotionX } from "../../components/Motion";

const Contact = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Contact"}
          text={`Contact information allows people to reach you for various reasons, whether it's for inquiries, collaboration opportunities, or just to get in touch.`}
        />
        <div className="map_sect">
          <div
            style={{
              overflow: "hidden",
              maxWidth: "100%",
              width: "100%",
              height: "500px",
            }}
          >
            <div
              id="my-map-display"
              style={{ height: "100%", width: "100%", maxWidth: "100%" }}
            >
              <iframe
                style={{ height: "100%", width: "100%", border: 0 }}
                frameborder="0"
                src="https://www.google.com/maps/embed/v1/search?q=U.S.+Virgin+Islands&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="contacts_sect">
          <div className="contacts_sect_item_one">
            <MotionX>
              <h1>
                <span>United State,</span> Virgin Islands
              </h1>
            </MotionX>
            <Motion>
              <p>1301 2nd Ave Suite 2600, United State, WA 98101</p>
            </Motion>
            <MotionX>
              <p className="contact_email_text">
                <span>Email: </span>
                support@odigifinance.com
              </p>
            </MotionX>
          </div>
          <div className="contacts_sect_item_two">
            <MotionRight>
              <p>
                <span>Call Directly: </span>
              </p>
            </MotionRight>
            <MotionRight>
              <h1>234504304003</h1>
            </MotionRight>
            <MotionRight>
              <p>
                <span>Brand Offices: </span>
              </p>
            </MotionRight>
            <MotionRight>
              <h3>
                Allentown PA | Allanta, GA | Chicago, IL | Dallas, TX, Edison,
                NJ | Houston, TX
              </h3>
            </MotionRight>
            <Motion>
              <p>
                <span>Work Hours: </span>
              </p>
            </Motion>
            <MotionRight>
              <h3>Mon - Sat: 8.00 - 17.00</h3>
            </MotionRight>
          </div>
        </div>
        <div className="contact_form_header_sect">
          <MotionRight>
            <h3>Contact Us</h3>
          </MotionRight>
          <Motion>
            <h1>We Are Always Here To Help You</h1>
          </Motion>
          <form className="contact_form">
            <div>
              <input type="text" placeholder="Name *" />
              <input type="text" placeholder="Email *" />
              <input type="text" placeholder="Phone *" />
            </div>
            <textarea></textarea>
            <Button title={"Send Message"} />
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
