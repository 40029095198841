import React, { useState } from "react";
import "./styles.css";
import { IoMdClose } from "react-icons/io";

import { useGlobalContext } from "../../../../../context/context";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CiBank, CiMoneyBill } from "react-icons/ci";
import { BsBank, BsWallet2 } from "react-icons/bs";
import { MdNumbers } from "react-icons/md";
import { ColorRing } from "react-loader-spinner";

function AccountSettingsModal({ toggleDeposit }) {
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();
  const { userDetails, baseUrl, getUser } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { handleSubmit, register } = useForm();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;

  const {
    firstName,
    dob,
    maritalstatus,
    gender,
    city,
    address,
    lastName,
    middleName,
    age,
    _id,
  } = user;

  const handleUpdate = async (data) => {
    setloader(true);

    data.dob = data.dob ? data.dob : dob;
    data.city = data.city ? data.city : city;
    data.address = data.address ? data.address : address;
    data.age = data.age ? data.age : age;
    data.gender = data.gender ? data.gender : gender;
    data.firstName = data.firstName ? data.firstName : firstName;
    data.lastName = data.lastName ? data.lastName : lastName;
    data.middleName = data.middleName ? data.middleName : middleName;
    data.maritalstatus = data.maritalstatus
      ? data.maritalstatus
      : maritalstatus;

    try {
      const updateUser = await axios.put(`${baseUrl}users/${_id}`, data, {
        headers: { token: accessToken },
      });
      if (updateUser.status === 200) {
        setloader(false);
        getUser(accessToken, _id);
        toast.success("Details Updated");
        setTimeout(() => {
          toggleDeposit();
        }, 2000);
      }
    } catch (error) {
      setloader(false);
    }
  };

  return (
    <section className="deposit_modal account_settings_modal">
      <div className="deposit_modal_item_one" onClick={toggleDeposit}></div>
      <div className="deposit_modal_item_two">
        <div className="deposit_header">
          <IoMdClose color="#7913e5" size={28} onClick={toggleDeposit} />
        </div>
        <form
          className="deposit_form"
          onSubmit={handleSubmit((data) => handleUpdate(data))}
        >
          <div className="deposit_form_header">
            <h2>Account Settings</h2>
            <div className="deposit_step_two deposit_form_header">
              <div className="bank_deposit">
                <p>First Name</p>
                <div className="input_wrapper">
                  <CiMoneyBill color="#888ea8" size={22} />
                  <input
                    type="text"
                    placeholder="First Name"
                    {...register("firstName")}
                    defaultValue={firstName}
                  />
                </div>
                <p>Last Name</p>
                <div className="input_wrapper">
                  <CiMoneyBill color="#888ea8" size={22} />
                  <input
                    type="text"
                    placeholder="Last Name"
                    {...register("lastName")}
                    defaultValue={lastName}
                  />
                </div>
                <button className="btn">
                  {loader ? (
                    <ColorRing
                      visible={true}
                      height="45"
                      width="45"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={["white", "white", "white", "white", "white"]}
                    />
                  ) : (
                    "Update Details"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default AccountSettingsModal;
