import { toast } from "react-toastify";
import StepFour from "./components/StepFour";
import StepOne from "./components/StepOne";
import StepThree from "./components/StepThree";
import StepTwo from "./components/StepTwo";

export const checkSteps = (
  getValues,
  setCompletedStep,
  completedStep,
  validateRegistration,
  data
) => {
  const {
    firstName,
    lastName,
    middleName,
    age,
    city,
    phone,
    email,
    dob,
    address,
    maritalstatus,
    gender,
    password,
    confirmpassword,
  } = getValues();
  if (
    firstName !== "" &&
    lastName !== "" &&
    middleName !== "" &&
    age !== "" &&
    completedStep === 0
  ) {
    setCompletedStep(1);
  }
  if (firstName === "" || lastName === "" || middleName === "" || age === "") {
    toast.error("All input field must be valid");
  }
  if (
    dob !== "" &&
    city !== "" &&
    email !== "" &&
    phone !== "" &&
    completedStep === 1
  ) {
    setCompletedStep(2);
  }
  if (dob === "" || city === "" || email === "" || phone === "") {
    toast.error("All input field must be valid");
  }
  if (
    maritalstatus !== "" &&
    gender !== "" &&
    address !== "" &&
    completedStep === 2
  ) {
    setCompletedStep(3);
  }
  if (maritalstatus === "" || gender === "" || address === "") {
    toast.error("All input field must be valid");
  }
  if (
    password !== "" &&
    confirmpassword !== "" &&
    password == confirmpassword &&
    completedStep === 3
  ) {
    validateRegistration(data);
  }
  if (
    password === "" ||
    confirmpassword === "" ||
    password !== confirmpassword
  ) {
    toast.error("All input field must be valid");
  }
};

export const showSteps = (completedStep, register) => {
  return completedStep === 0 ? (
    <StepOne register={register} />
  ) : completedStep === 1 ? (
    <StepTwo register={register} />
  ) : completedStep === 2 ? (
    <StepThree register={register} />
  ) : completedStep === 3 ? (
    <StepFour register={register} />
  ) : (
    ""
  );
};

export const checkText = (completedStep, loading, wait, almost) => {
  return almost
    ? "Please wait"
    : wait
    ? "Validating..."
    : loading
    ? "Loading..."
    : completedStep === 0 || completedStep === 1 || completedStep === 2
    ? "Next Step"
    : completedStep === 3
    ? "Sign Up"
    : "";
};
