import React, { useEffect, useState } from "react";
import "./styles.css";
import { IoShieldOutline } from "react-icons/io5";
import { GiChart } from "react-icons/gi";
import historyImage from "../../../../asset/history Image.png";
import { DashboardHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";
import moment from "moment";
import { CgUnavailable } from "react-icons/cg";
import { Button } from "../../../../components";
import { WithdrawalModal } from "../../components/Modal";
import { SiCashapp, SiZelle } from "react-icons/si";
import { BsBank } from "react-icons/bs";
import { FaPaypal } from "react-icons/fa";
import axios from "axios";

function Overview() {
  const [SelectedMethod, setSelectedMethod] = useState("");
  const {
    userDetails,
    getUser,
    moneyFormatter,
    getAllLoans,
    getAllWithdrawals,
    withdrawalHistory,
    getAllDeposits,
    baseUrl,
  } = useGlobalContext();

  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const {
    _id,
    firstName,
    country,
    profit,
    bonus,
    newUser,
    accountNumber,
    accountType,
    lastName,
    userOtp,
  } = user;
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const loans = JSON.parse(sessionStorage.getItem("loans"));
  const loantransfers = JSON.parse(sessionStorage.getItem("loanwithdraw"));
  const deposits = JSON.parse(sessionStorage.getItem("deposits"));
  const investtransfers = JSON.parse(sessionStorage.getItem("investwithdraw"));
  const transfers = JSON.parse(sessionStorage.getItem("withdraw"));
  const [withdrawModal, setWithdrawModal] = useState(false);
  const totalAmountAccumulated = profit + loans + deposits + bonus - transfers;
  const today = new Date();
  const currentHour = today.getHours();

  const { symbol } = country;
  const toggleWithdrawModal = (value) => {
    setWithdrawModal(!withdrawModal);
    setSelectedMethod(value);
  };

  const greetings =
    currentHour >= 12 && currentHour < 16
      ? "Good afternoon, wash your hands 🌙"
      : currentHour < 12
      ? "Good morning, wash your hands 🌤"
      : "Good evening, wash your hands 🌙";

  useEffect(() => {
    getUser(accessToken, _id);
    getAllLoans(accessToken, _id);
    getAllWithdrawals(accessToken, _id);
    getAllDeposits(accessToken, _id);
  }, []);

  return (
    <>
      {newUser ? (
        <section className="overview_sect">
          <DashboardHeader
            title={`Welcome ${firstName} ${newUser ? lastName : ""}`}
            text={greetings}
          />

          <h3 className="overview_history_text">Withdrawals Methods</h3>
          <div className="overview_payment_methods">
            <div>
              <div
                className="with_icon_wrapper"
                onClick={() => toggleWithdrawModal("paypal")}
              >
                <FaPaypal size={20} color="white" />
              </div>
              <h3>Paypal transfer</h3>
            </div>
            <div>
              <div
                className="with_icon_wrapper"
                onClick={() => toggleWithdrawModal("bank transfer")}
              >
                <BsBank size={20} color="white" />
              </div>
              <h3>International bank transfer</h3>
            </div>
            <div>
              <div
                className="with_icon_wrapper"
                onClick={() => toggleWithdrawModal("cashapp")}
              >
                <SiCashapp size={20} color="white" />
              </div>

              <h3>Cashapp transfer</h3>
            </div>
            <div>
              <div
                className="with_icon_wrapper"
                onClick={() => toggleWithdrawModal("zelle")}
              >
                <SiZelle size={20} color="white" />
              </div>
              <h3>Zelle transfer</h3>
            </div>
          </div>
          <h3 className="overview_history_text">Account Dashboard</h3>
          <div className="accumulation_sect_wrapper">
            <div className="accumulation_sect">
              <div>
                <GiChart />
                <div>
                  <p>Available Balance</p>
                  <h2>
                    {symbol}
                    {moneyFormatter.format(profit - transfers)}
                  </h2>
                </div>
              </div>
              <div>
                <IoShieldOutline />
                <div>
                  <p>Account Number</p>
                  <h2>{accountNumber}</h2>
                </div>
              </div>
              <div className="investment_wrapper">
                <h3 style={{ fontSize: 30 }}>{symbol}</h3>
                <div>
                  <p>Account Type</p>
                  <h2>{accountType}</h2>
                </div>
              </div>
            </div>
          </div>

          <h3 className="overview_history_text">History Overview</h3>
          {withdrawalHistory.length > 0 ? (
            withdrawalHistory?.map((withdraw, index) => {
              const { amount, status, createdAt } = withdraw;
              return (
                <div key={index} className="history_sect">
                  <div className="history_sect_item">
                    <div>
                      <img src={historyImage} alt="historyimage" />
                      <div>
                        <p className="statement_text">
                          {status ? "Approved" : "Pending"}
                        </p>
                        <p className="date_text">
                          {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                        </p>
                      </div>
                    </div>
                    <p className="date_text">
                      {symbol}
                      {moneyFormatter.format(amount)}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="history_sect">
              <div className="history_sect_item empty_wrapper">
                <CgUnavailable size={50} color="#e2e8f0" />
              </div>
            </div>
          )}
          {withdrawModal && (
            <WithdrawalModal
              toggleWithdrawModal={toggleWithdrawModal}
              totalAmount={profit - transfers}
              withdrawalPath={"overview"}
              method={SelectedMethod}
            />
          )}
        </section>
      ) : (
        <section className="overview_sect">
          <DashboardHeader title={firstName} text={greetings} />
          <div className="accumulation_sect_wrapper">
            {profit !== 0 && (
              <Button
                title={"Withdraw"}
                className={"button"}
                action={toggleWithdrawModal}
              />
            )}
            <div className="accumulation_sect">
              <div>
                <IoShieldOutline />
                <div>
                  <p>Total Amount</p>
                  <h2>
                    {symbol}
                    {moneyFormatter.format(totalAmountAccumulated)}
                  </h2>
                </div>
              </div>
              <div>
                <GiChart />
                <div>
                  <p>Loan Balance</p>
                  <h2>
                    {symbol}
                    {moneyFormatter.format(loans - loantransfers)}
                  </h2>
                </div>
              </div>
              <div className="investment_wrapper">
                <h3 style={{ fontSize: 30 }}>{symbol}</h3>
                <div>
                  <p>Investment Balance</p>
                  <h2>
                    {symbol}
                    {moneyFormatter.format(deposits + bonus - investtransfers)}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {withdrawalHistory.length > 0 ? (
            withdrawalHistory?.map((withdraw, index) => {
              const { amount, status, createdAt } = withdraw;
              return (
                <div key={index} className="history_sect">
                  <div className="history_sect_item">
                    <div>
                      <img src={historyImage} alt="historyimage" />
                      <div>
                        <p className="statement_text">
                          {status ? "Approved" : "Pending"}
                        </p>
                        <p className="date_text">
                          {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                        </p>
                      </div>
                    </div>
                    <p className="date_text">
                      {symbol}
                      {moneyFormatter.format(amount)}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="history_sect">
              <div className="history_sect_item empty_wrapper">
                <CgUnavailable size={50} color="#e2e8f0" />
              </div>
            </div>
          )}
          {withdrawModal && (
            <WithdrawalModal
              toggleWithdrawModal={toggleWithdrawModal}
              totalAmount={profit - transfers}
              withdrawalPath={"overview"}
            />
          )}
        </section>
      )}
    </>
  );
}

export default Overview;
