import React from "react";
import { BsWallet2 } from "react-icons/bs";
import { CiBank } from "react-icons/ci";

function StepOne({ handleActiveTab }) {
  return (
    <div className="deposit_form_header">
      <h2>Select a Method To Deposit</h2>
      <div className="bank_deposit">
        <div onClick={() => handleActiveTab(2)} className="history_sect">
          <CiBank size={28} color="var(--pri-clr)" />
          <div>
            <h3>Bank Transfer</h3>
            <p className="date_text">
              Easily make deposit through bank transfer
            </p>
          </div>
        </div>
        <div onClick={() => handleActiveTab(3)} className="history_sect">
          <BsWallet2 size={28} color="var(--pri-clr)" />
          <div>
            <h3>Deposit Through Crypto</h3>
            <p className="date_text">Make deposit through wallet addresses</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
