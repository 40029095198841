import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { checkCurrentStep, checkSteps, checkText } from "./helper-method";
import { useGlobalContext } from "../../../../../context/context";
import axios from "axios";

function LoanModal({ toggleLoanModal, toggleLoanPendingModal }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [processingLoader, setProcessingLoader] = useState(false);
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { register, handleSubmit, getValues } = useForm();
  const { getAllLoans, baseUrl, userDetails } = useGlobalContext();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const { email, firstName, lastName, _id } = user;

  const validateLoan = (data) => {
    setLoading(true);
    data.email = email;
    data.name = `${firstName} ${lastName}`;
    data.userId = _id;

    setTimeout(() => {
      setProcessingLoader(true);
    }, 5000);

    setTimeout(() => {
      axios
        .post(`${baseUrl}loan`, data, { headers: { token: accessToken } })
        .then((response) => {
          toggleLoanPendingModal();
          getAllLoans(accessToken, _id);
          toggleLoanModal();
          setLoading(false);
          setProcessingLoader(false);
        })
        .catch((err) => {
          setProcessingLoader(false);
          setLoading(false);
        });
    }, 15000);
  };
  const handleLoan = (data) => {
    checkSteps(getValues, setCurrentStep, currentStep, validateLoan, data);
  };

  return (
    <section className="deposit_modal loan_wrapper_con_modal">
      <div className="deposit_modal_item_one" onClick={toggleLoanModal}></div>
      <div className="deposit_modal_item_two withdraw_modal_item_two loan_modal">
        <div className="deposit_header">
          <IoMdClose color="#7913e5" size={28} onClick={toggleLoanModal} />
        </div>
        <form
          className="deposit_form"
          onSubmit={handleSubmit((data) => handleLoan(data))}
        >
          <div className="deposit_step_two deposit_form_header">
            <h2>Get Started with your loan!</h2>
            <div className="bank_deposit">
              {checkCurrentStep(currentStep, register)}
              <button className="btn">
                {checkText(currentStep, loading, processingLoader)}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default LoanModal;
