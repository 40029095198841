export const loans = [
  { name: "Personal Loans", path: "personal-loans" },
  { name: "Home Mortgage Loans", path: "home-mortgage-loans" },
  { name: "Investment Property Loans", path: "investment-property-loans" },
  { name: "Working Capital Loans", path: "working-capital-loans" },
  {
    name: "Commercial Real Estate Loans",
    path: "commercial-real-estate-loans",
  },
];

export const business = [
  {
    name: "Overdraft Protection & Sweeps",
    path: "overdraft-protection-and-sweeps",
  },
  { name: "Business Essential Checking", path: "business-essential-checking" },
  { name: "Business Savings Account", path: "business-savings-account" },
];

export const personal = [
  { name: "Ultimate Checking", path: "ultimate-checking" },
  { name: "Health Savings Account (HSA)", path: "health-savings-account" },
  {
    name: "Individual Retirement Account (IRA)s",
    path: "individual-retirement-account",
  },
];
export const services = [
  { name: "Online Banking", path: "online-banking" },
  { name: "Wire Transfer", path: "wire-transfer" },
  { name: "Lost Or Stolen Card", path: "lost-or-stolen-card" },
];
