import React, { useState } from "react";
import { FiLock } from "react-icons/fi";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Link } from "react-router-dom";

function StepFour({ register }) {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => setVisible(!visible);
  return (
    <>
      <p>Password </p>
      <div className="input_wrapper">
        <FiLock color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
        <input
          type={visible ? "text" : "password"}
          placeholder="Your Password"
          className="password"
          {...register("password")}
        />
        {visible ? (
          <MdVisibility
            color="#888ea8"
            size={22}
            cursor="pointer"
            onClick={toggleVisible}
          />
        ) : (
          <MdVisibilityOff
            color="#888ea8"
            size={22}
            cursor="pointer"
            onClick={toggleVisible}
          />
        )}
      </div>
      <p>Confirm Password </p>
      <div className="input_wrapper">
        <FiLock color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
        <input
          type={visible ? "text" : "password"}
          placeholder="Your Password"
          className="password"
          {...register("confirmpassword")}
        />
        {visible ? (
          <MdVisibility
            color="#888ea8"
            size={22}
            cursor="pointer"
            onClick={toggleVisible}
          />
        ) : (
          <MdVisibilityOff
            color="#888ea8"
            size={22}
            cursor="pointer"
            onClick={toggleVisible}
          />
        )}
      </div>
      <div className="create_account_text_wrapper forget_password_text_wrapper">
        <p></p>
        <Link to={"/login"}>Already Have An Account? Sign In</Link>
      </div>
    </>
  );
}

export default StepFour;
