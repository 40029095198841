import React from "react";
import { Body, Footer, Header, PageHero } from "../../../components";
import { commercialRealEstate } from "../contants";

const CommercialRealEstate = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Commercial Real Estate Loans"}
          text={commercialRealEstate}
        />
        <Body
          text={`Commercial real estate loans play a pivotal role in facilitating
              property acquisitions, developments, and refinancing for
              businesses of all sizes. By understanding the various types of
              commercial real estate financing options, evaluating their
              suitability for your investment goals, and conducting thorough due
              diligence, you can navigate the commercial real estate lending
              landscape with confidence and unlock the potential of commercial
              properties in your portfolio.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default CommercialRealEstate;
