import React from "react";
import { PageHero, Footer, Header, Body2 } from "../../../components";
import "./styles.css";

const OnlineBanking = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper online_banking_sect">
        <PageHero btnTitle={"Home Page"} />
        <Body2
          title={"Online Banking"}
          text={` Online banking empowers individuals and businesses with unparalleled
          convenience, accessibility, and security in managing their finances.
          By leveraging the core features, enhanced security measures, and
          additional services offered by online banking platforms, customers can
          streamline financial management, save time and costs, and gain greater
          control over their financial lives. Embracing online banking is not
          just a trend but a transformative shift toward a more efficient and
          convenient banking experience.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default OnlineBanking;
