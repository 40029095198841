import React, { useEffect, useState } from "react";
import { DashboardHeader } from "../../components";
import accountImage from "../../../../asset/Saving-dark.png";
import "./styles.css";
import { IoMdShare } from "react-icons/io";
import { FiLogOut, FiPhone, FiUser } from "react-icons/fi";
import { IoHelp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../context/context";
import AccountSettingsModal from "../../components/Modal/AccountSettingsModal";

function Account() {
  const [accountSettingsModal, setaccountSettingsModal] = useState(false);
  const navigate = useNavigate();
  const { getUser, userDetails, moneyFormatter } = useGlobalContext();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const transfers = JSON.parse(sessionStorage.getItem("withdraw"));
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const deposits = JSON.parse(sessionStorage.getItem("deposits"));

  const { _id, firstName, lastName, middleName, country, profit } = user;
  const { symbol } = country;

  const logOut = () => {
    sessionStorage.removeItem("accessToken");
    navigate("/auth/login");
  };

  const toggleAccountSettingsModal = () =>
    setaccountSettingsModal(!accountSettingsModal);

  useEffect(() => {
    getUser(accessToken, _id);
  }, []);
  return (
    <section className="account_wrapper">
      <DashboardHeader
        text={`${firstName} ${lastName} ${middleName}`}
        title={"Account Details"}
      />
      <div className="account_tab_sect">
        <div className="account_tab_sect_item_one">
          <img src={accountImage} alt="" />
          <div>
            <div className="history_sect" onClick={toggleAccountSettingsModal}>
              <FiUser size={26} color="black" />
              <p className="date_text">Account Settings</p>
            </div>
            <div className="history_sect">
              <IoHelp size={26} color="black" />
              <p className="date_text">Self Help</p>
            </div>
            <div className="history_sect">
              <IoMdShare size={26} color="black" />
              <p className="date_text">Refer & earn</p>
            </div>
            <a href="mailto:support@ogidifinance.com" className="history_sect">
              <FiPhone size={26} color="black" />
              <p className="date_text">Contact Us</p>
            </a>
            <div className="history_sect" onClick={logOut}>
              <FiLogOut size={26} color="black" />
              <p className="date_text">Log Out</p>
            </div>
          </div>
        </div>
        <div className="account_tab_sect_item_two">
          <div className="user_prof">
            <h2>
              {firstName[0]}
              {lastName[0]}
            </h2>
          </div>
          <h3>
            {firstName} {lastName} {middleName}
          </h3>
          <div className="account_history_sect">
            <div className="history_sect">
              <h3>{_id.slice(0, 15)}</h3>
              <p>User Id</p>
            </div>
            <div className="history_sect">
              <h3>0</h3>
              <p>Quatums Point</p>
            </div>
            <div className="history_sect">
              <h3>{symbol}0</h3>
              <p>Referral Earnings</p>
            </div>
            <div className="history_sect">
              <h3>
                {symbol}
                {moneyFormatter.format(deposits)}
              </h3>
              <p>Total Deposits</p>
            </div>
            <div className="history_sect">
              <h3>
                {symbol}
                {moneyFormatter.format(transfers)}
              </h3>
              <p>Total Withdrawals</p>
            </div>
            <div className="history_sect">
              <h3>
                {symbol}
                {moneyFormatter.format(profit)}
              </h3>
              <p>Total Profit</p>
            </div>
          </div>
        </div>
      </div>
      {accountSettingsModal && (
        <AccountSettingsModal toggleDeposit={toggleAccountSettingsModal} />
      )}
    </section>
  );
}

export default Account;
