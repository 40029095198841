import { StepFour, StepOne, StepThree, StepTwo } from "./components";

export const checkDepositStep = (
  activeStep,
  handleActiveTab,
  register,
  handleImageChange,
  uploadedImage,
  loader
) => {
  return activeStep === 1 ? (
    <StepOne handleActiveTab={handleActiveTab} />
  ) : activeStep === 2 ? (
    <StepTwo handleActiveTab={handleActiveTab} />
  ) : activeStep === 3 ? (
    <StepThree handleActiveTab={handleActiveTab} />
  ) : (
    <StepFour
      register={register}
      handleImageChange={handleImageChange}
      uploadedImage={uploadedImage}
      loader={loader}
    />
  );
};
