import React from "react";
import { Body3, Footer, Header, PageHero } from "../../../components";

function HealthSavingsAccount() {
  return (
    <>
      <Header />
      <section className="personal_wrapper online_banking_sect">
        <PageHero btnTitle={"Home Page"} />
        <Body3
          title={"Health Savings Account"}
          text={`When considering loans and investments, HSAs offer several
              advantages. First, contributions to HSAs are tax-deductible,
              providing immediate tax savings for individuals. Additionally,
              funds within the HSA can be invested, allowing for potential
              growth over time. Furthermore, withdrawals from HSAs for qualified
              medical expenses are tax-free, making them a tax-efficient way to
              cover healthcare costs. HSAs offer a unique blend of savings and
              investment opportunities, making them a valuable tool for
              individuals seeking to manage healthcare expenses while
              potentially growing their funds for the future.`}
        />
      </section>
      <Footer />
    </>
  );
}

export default HealthSavingsAccount;
