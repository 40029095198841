import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { MdNumbers } from "react-icons/md";
import { Slide, toast } from "react-toastify";
import axios from "axios";
import { useGlobalContext } from "../../../../../context/context";
import { BsPassport } from "react-icons/bs";

function KYCModal({ toggleKYCModal }) {
  const [idBackImage, setidBackImage] = useState(null);
  const [idFrontImage, setidFrontImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    back: [],
    front: [],
    userId: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, front: e.target.files });
      setidFrontImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleBackImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, back: e.target.files });
      setidBackImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const { baseUrl, kyc, userDetails } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const { _id, email } = user;

  const handleKYCSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("userId", _id);
    data.append("email", email);
    data.append("number", formData.number);
    for (let i = 0; i < formData.front.length; i++) {
      data.append("front", formData.front[i]);
    }
    for (let i = 0; i < formData.back.length; i++) {
      data.append("back", formData.back[i]);
    }
    try {
      const kycPost = await axios.post(`${baseUrl}kyc`, data, {
        headers: { token: accessToken },
      });
      if (kycPost?.status === 200) {
        setLoading(false);
        toast.success("KYC Details Uploaded");
        setTimeout(() => {
          toggleKYCModal();
        }, 3000);
      }
    } catch (err) {
      setLoading(false);

      if (
        err?.response?.data?.keyPattern?.idnumber === 1 ||
        err?.response?.data?.keyPattern?.idname === 1 ||
        err?.response?.data?.keyPattern?.email === 1
      ) {
        toast.error("Details Already Exist", {
          transition: Slide,
          theme: "dark",
        });
      }
    }
  };

  return (
    <section className="deposit_modal">
      <div className="deposit_modal_item_one" onClick={toggleKYCModal}></div>
      <div className="deposit_modal_item_two withdraw_modal_item_two">
        <div className="deposit_header">
          <IoMdClose color="#7913e5" size={28} onClick={toggleKYCModal} />
        </div>
        <form
          onSubmit={handleKYCSubmit}
          className="deposit_form"
          encType="multipart/form-data"
        >
          <div className="deposit_step_two deposit_form_header">
            <h2>Upload KYC Details To Access Withdrawal Portal</h2>
            <div className="bank_deposit">
              <p>ID Number</p>
              <div className="input_wrapper">
                <MdNumbers color="#888ea8" size={22} />
                <input
                  type="text"
                  placeholder="ID Number"
                  name="number"
                  onChange={handleChange}
                  value={formData.number}
                />
              </div>
              <p>ID Name</p>
              <div className="input_wrapper">
                <BsPassport color="#888ea8" size={22} />
                <input
                  type="text"
                  placeholder="ID Name"
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </div>
              <p>ID Card Front</p>
              <input
                id="idfront"
                name="idfront"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={handleFrontImageChange}
              />
              <label htmlFor="idfront" className="button">
                Upload Card Front
              </label>
              {idFrontImage !== null && (
                <img src={idFrontImage} className="proof_image" alt="" />
              )}
              <p>ID Card Back</p>
              <label htmlFor="idback" className="button">
                Upload Card Back
              </label>
              {idBackImage !== null && (
                <img src={idBackImage} className="proof_image" />
              )}
              <input
                id="idback"
                name="idback"
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={handleBackImageChange}
              />
              <button className="btn">
                {loading ? "Loading..." : "Update KYC"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default KYCModal;
