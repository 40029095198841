import React from "react";
import { Body, Footer, Header, PageHero } from "../../../components";
import { investmentPropertyLoans } from "../contants";

const InvestmentPropertyLoans = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Investment Property Loans"}
          text={investmentPropertyLoans}
        />
        <Body
          text={`Loans for investment properties are essential for real estate
              investing since they provide chances for portfolio diversification
              and revenue growth. Through a comprehensive understanding of the
              intricacies involved in these loans and diligent due diligence,
              investors may make well-informed judgments and fully realize the
              potential of investment properties. To create a plan that fits
              your investing objectives and risk tolerance, always seek advice
              from financial and real estate experts.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default InvestmentPropertyLoans;
