import React from "react";
import loan from "../../asset/home-landing.webp";
import Button from "../Button";
import { MotionRight, MotionX } from "../Motion";

const Body2 = ({ title, text }) => {
  return (
    <div className="personal_loan_footer">
      <div className="personal_loan_footer_image_sect">
        <img src={loan} alt="" />
      </div>
      <div className="home_why_choose_sect_item_one">
        <MotionRight>
          <h1>{title}</h1>
        </MotionRight>
        <MotionX>
          <p>{text}</p>
        </MotionX>
        <MotionX>
          <div className="personal_loan_footer_btn_wrapper">
            <Button title={"Loans"} />
            <Button title={"Savings"} />
            <Button title={"Interest"} />
            <Button title={"Benefits"} />
            <Button title={"Travel"} />
          </div>
        </MotionX>
      </div>
    </div>
  );
};

export default Body2;
