import axios from "axios";
import React, { useContext, useMemo, useState } from "react";
import countryList from "react-select-country-list";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseUrl = "https://ogidi-finance-api.onrender.com/api/";
  // const baseUrl = "http://localhost:5003/api/";
  const [selectedCountry, setselectedCountry] = useState(``);
  const [allCountries, setallCountries] = useState([]);
  const [value, setValue] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [depositHistory, setDepositHistory] = useState([]);
  const [depositLoading, setDepositLoading] = useState(false);
  const [loanHistory, setloanHistory] = useState([]);
  const [loanLoading, setloanLoading] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState(false);
  const [withdrawalLoader, setWithdrawalLoader] = useState(false);
  const [kyc, setKyc] = useState(null);
  let moneyFormatter = new Intl.NumberFormat({
    style: "currency",
    currency: "USD",
  });

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
    setselectedCountry(
      allCountries.filter((country) => country.name.common === value.label)
    );
  };

  // Get Deposits
  const getAllDeposits = (accessToken, _id) => {
    setDepositLoading(true);
    axios
      .get(`${baseUrl}deposit/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setDepositHistory(data.data.deposit);
        setDepositLoading(false);
        sessionStorage.setItem(
          "deposits",
          JSON.stringify(
            data.data.deposit
              .filter((deposits) => deposits.status === "true")
              .reduce((accumulator, { amount }) => accumulator + amount / 1, 0)
          )
        );
      })
      .catch((error) => {
        setDepositLoading(false);
      });
  };

  // Get Loans
  const getAllLoans = (accessToken, _id) => {
    setloanLoading(true);
    axios
      .get(`${baseUrl}loan/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setloanHistory(data.data.loan[0]);
        setloanLoading(false);
        sessionStorage.setItem("loans", JSON.stringify(data.data.totalAmount));
      })
      .catch((error) => {
        setloanLoading(false);
      });
  };

  // Get Kyc Details
  const geKYCDetails = (accessToken, _id) => {
    axios
      .get(`${baseUrl}kyc/${_id}`, { headers: { token: accessToken } })
      .then((response) => {
        setKyc(response.data[0] || []);
        sessionStorage.setItem("kyc", JSON.stringify(response.data[0] || []));
      })
      .catch((error) => {});
  };

  // Get User Details
  const getUser = (token, id) => {
    setUserLoading(true);
    axios
      .get(`${baseUrl}users/${id}`, {
        headers: { token },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserLoading(false);
          setUserDetails(data.data);
          sessionStorage.setItem("user", JSON.stringify(data.data));
        }
      })
      .catch((error) => {});
  };

  // Get All Withdrawals
  const getAllWithdrawals = (accessToken, _id) => {
    setWithdrawalLoader(true);

    axios
      .get(`${baseUrl}transfer/${_id}`, {
        headers: { token: accessToken },
      })
      .then((data) => {
        setWithdrawalLoader(false);
        setWithdrawalHistory(data.data.transfers);
        sessionStorage.setItem(
          "withdraw",
          JSON.stringify(data.data.totalAmountTransferred)
        );
        sessionStorage.setItem(
          "loanwithdraw",
          JSON.stringify(
            data.data.transfers
              .filter((transfer) => transfer.withdrawalPath === "loan")
              .reduce((accumulator, { amount }) => accumulator + amount / 1, 0)
          )
        );
        sessionStorage.setItem(
          "investwithdraw",
          JSON.stringify(
            data.data.transfers
              .filter((transfer) => transfer.withdrawalPath === "invest")
              .reduce((accumulator, { amount }) => accumulator + amount / 1, 0)
          )
        );
      })
      .catch((error) => {
        setWithdrawalLoader(false);
      });
  };

  const getAllWithdrawalss = (token) => {
    axios
      .get(`${baseUrl}transfer`, {
        headers: { token: token },
      })
      .then((data) => {})
      .catch((error) => {});
  };

  return (
    <AppContext.Provider
      value={{
        baseUrl,
        selectedCountry,
        setallCountries,
        options,
        value,
        changeHandler,
        userDetails,
        getUser,
        userLoading,
        moneyFormatter,
        getAllLoans,
        getAllDeposits,
        loanHistory,
        depositHistory,
        depositLoading,
        loanLoading,
        geKYCDetails,
        kyc,
        getAllWithdrawals,
        withdrawalHistory,
        withdrawalLoader,
        getAllWithdrawalss,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
