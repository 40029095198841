import React from "react";
import { FaCity } from "react-icons/fa";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";

function StepTwo({ register }) {
  return (
    <>
      <p>City </p>
      <div className="input_wrapper">
        <FaCity color="#888ea8" size={22} />
        <input type="text" placeholder="City* " {...register("city")} />
      </div>
      <p>Phone</p>
      <div className="input_wrapper">
        <MdOutlinePhone color="#888ea8" size={22} />
        <input type="tel" placeholder="Phone* " {...register("phone")} />
      </div>
      <p>Email</p>
      <div className="input_wrapper">
        <MdOutlineEmail color="#888ea8" size={22} />
        <input type="email" placeholder="Email* " {...register("email")} />
      </div>
      <p>Date of Birth</p>
      <div className="input_wrapper">
        <CiCalendarDate color="#888ea8" size={22} />
        <input type="text" placeholder="Date of Birth* " {...register("dob")} />
      </div>
    </>
  );
}

export default StepTwo;
