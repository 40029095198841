import React from "react";
import { MobileNavbar, Navbar } from "../../components";
import { Route, Routes, useLocation } from "react-router-dom";
import Overview from "../Overview";
import "./styles.css";
import Loan from "../Loan";
import Account from "../Account";
import Invest from "../Invest";

function Dashboard() {
  const { pathname } = useLocation();
  return (
    <section className="dasboard">
      <Navbar path={pathname} />
      <MobileNavbar />
      <div
        style={{ padding: pathname === "/dashboard-overview" ? 0 : null }}
        className="dashboard_pages"
      >
        <Routes>
          <Route path="/dashboard-overview" element={<Overview />} />
          <Route path="/loan" element={<Loan />} />
          <Route path="/account-details" element={<Account />} />
          <Route path="/investify" element={<Invest />} />
        </Routes>
      </div>
    </section>
  );
}

export default Dashboard;
