import React from "react";
import loan from "../../asset/personal-loan.jpg";
import Button from "../Button";
import { Motion, MotionRight, MotionX } from "../Motion";

const Body3 = ({ title, text }) => {
  return (
    <div className="personal_loan_footer">
      <div className="personal_loan_footer_image_sect">
        <img src={loan} alt="" />
      </div>
      <div className="home_why_choose_sect_item_one">
        <Motion>
          <h1>{title}</h1>
        </Motion>
        <MotionX>
          <p>{text}</p>
        </MotionX>
        <div className="personal_loan_footer_btn_wrapper">
          <MotionX>
            <Button title={"Loans"} />
          </MotionX>
          <MotionRight>
            <Button title={"Savings"} />
          </MotionRight>
          <Motion>
            <Button title={"Interest"} />
          </Motion>
          <MotionX>
            <Button title={"Benefits"} />
          </MotionX>
          <Motion>
            <Button title={"Travel"} />
          </Motion>
        </div>
      </div>
    </div>
  );
};

export default Body3;
