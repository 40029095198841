import { Fragment, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import {
  Contact,
  CreateAccount,
  ForgetPassword,
  Home,
  Login,
  ResetPassword,
} from "./pages";
import {
  HealthSavingsAccount,
  IndividualsRetirementAccount,
  UltimateChecking,
} from "./pages/Personal";
import {
  BusinessEssentialChecking,
  BusinessSavingsAccount,
  OverdraftProtectionAndSweeps,
} from "./pages/Business";
import {
  CommercialRealEstate,
  HomeMortgageLoans,
  InvestmentPropertyLoans,
  PersonalLoans,
  WorkingCapitalLoans,
} from "./pages/Loans";
import {
  LostOrStolenCard,
  OnlineBanking,
  WireTransfer,
} from "./pages/Services";

import Dashboard from "./pages/UserDasboard/pages/Dashboard";
import { Slide, toast, ToastContainer } from "react-toastify";
import PrivateRoute from "./utils/PrivateRoute";
import { client } from "./utils/client";

function App() {
  const { pathname } = useLocation();
  const [testimony, setTestimony] = useState([]);
  const [counter, setCounter] = useState(1);

  const getProofs = () => {
    client
      .fetch(
        `*[_type == "proof"] {
            name,
           brief,
           country
        }`
      )
      .then((data) => {
        setTestimony(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setTimeout(() => {
      let prooflength = testimony.length - 1;
      if (counter === prooflength) {
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }
    }, 35000);
    toast.success(
      `${
        testimony.length === 0 ? "Ogidi Finance" : testimony[counter]?.name
      }: ${
        testimony.length === 0
          ? "Your Way To Riches"
          : `from ${testimony[counter]?.country} ${testimony[counter]?.brief}`
      }`,
      {
        transition: Slide,
        position: "bottom-left",
      }
    );
  }, [counter]);

  useEffect(() => {
    getProofs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="*" element={<Dashboard />} />
        </Route>
        <Route element={<Home />} path="/" />
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route element={<PersonalLoans />} path="/personal-loans" />
        <Route element={<HomeMortgageLoans />} path="/home-mortgage-loans" />
        <Route element={<OnlineBanking />} path="/online-banking" />
        <Route element={<LostOrStolenCard />} path="/lost-or-stolen-card" />
        <Route element={<WireTransfer />} path="/wire-transfer" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<UltimateChecking />} path="/ultimate-checking" />
        <Route
          element={<IndividualsRetirementAccount />}
          path="/individual-retirement-account"
        />
        <Route
          element={<WorkingCapitalLoans />}
          path="/working-capital-loans"
        />
        <Route
          element={<InvestmentPropertyLoans />}
          path="/investment-property-loans"
        />
        <Route
          element={<CommercialRealEstate />}
          path="/commercial-real-estate-loans"
        />
        <Route
          element={<OverdraftProtectionAndSweeps />}
          path="/overdraft-protection-and-sweeps"
        />
        <Route
          element={<BusinessEssentialChecking />}
          path="/business-essential-checking"
        />
        <Route
          element={<BusinessSavingsAccount />}
          path="/business-savings-account"
        />
        <Route
          element={<HealthSavingsAccount />}
          path="/health-savings-account"
        />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
