import React from "react";
import { FiUser } from "react-icons/fi";

function StepOne({ register }) {
  return (
    <>
      <p>First Name</p>
      <div className="input_wrapper">
        <FiUser color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
        <input
          type="text"
          placeholder="First Name* "
          {...register("firstName")}
        />
      </div>
      <p>Surname</p>
      <div className="input_wrapper">
        <FiUser color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
        <input type="text" placeholder="Surname* " {...register("lastName")} />
      </div>
      <p>Other Name</p>
      <div className="input_wrapper">
        <FiUser color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
        <input
          type="text"
          placeholder="Other Name* "
          {...register("middleName")}
        />
      </div>
      <p>Account Type</p>
      <div className="input_wrapper">
        <FiUser color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
        <input
          type="text"
          placeholder="Savings* or Current "
          {...register("accountType")}
        />
      </div>
    </>
  );
}

export default StepOne;
