import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Slide, toast } from "react-toastify";
import { useGlobalContext } from "../../../../context/context";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const OTPModal = ({
  toggleOTPModal,
  toggleWithdrawalModal,
  getValues,
  withdrawalPath,
  method,
}) => {
  const [otpCode, setcode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userDetails, baseUrl, getUser, getAllWithdrawals } =
    useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;

  const {
    userOtp,
    _id,
    transferStep,
    email,
    country: { symbol },
    lastName,
    firstName,
  } = user;

  let supposeToOTP =
    transferStep == 1 ? 5536 : transferStep === 3 ? 4737 : 1000;

  const validateWithdrawal = () => {
    const data = getValues();
    let pzcDetails = {};
    pzcDetails.accountNumber = "1234567890";
    pzcDetails.accountType = "savings";
    pzcDetails.accountName = `${firstName} ${lastName}`;
    pzcDetails.transactiontype = method;
    pzcDetails.bankName = "ertyuytre";
    pzcDetails.userId = _id;
    pzcDetails.email = data.email;
    pzcDetails.symbol = symbol;
    pzcDetails.withdrawalPath = withdrawalPath;
    pzcDetails.amount = data.amount;

    const handleOtp = () => {
      axios
        .put(
          `${baseUrl}users/updatedata/${_id}`,
          { userOtp: supposeToOTP },
          { headers: { token: accessToken } }
        )
        .then((data) => {})
        .catch((error) => {});
    };

    if (otpCode == userOtp && transferStep !== 5) {
      setLoading(true);
      setTimeout(() => {
        axios
          .put(
            `${baseUrl}users/user/${_id}`,
            { transferStep: transferStep + 1 },
            { headers: { token: accessToken } }
          )
          .then((response) => {
            setLoading(false);
            toast.success("Withdrawal is Processing");
            getUser(accessToken, _id);
            setTimeout(() => {
              toggleOTPModal();
              handleOtp();
              toggleWithdrawalModal();
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
          });
      }, 3000);
    } else if (otpCode == userOtp && transferStep === 5) {
      data.email = email;
      data.userId = _id;
      data.symbol = symbol;
      data.withdrawalPath = withdrawalPath;
      data.transactiontype = method;

      setLoading(true);
      axios
        .post(
          `${baseUrl}transfer`,
          method === "bank transfer" ? data : pzcDetails,
          {
            headers: { token: accessToken },
          }
        )
        .then((response) => {
          toast.success("Withdrawal successful");
          setLoading(false);
          getUser(accessToken, _id);
          getAllWithdrawals(accessToken, _id);
          setTimeout(() => {
            toggleWithdrawalModal();
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.keyPattern?.referenceNumber === 1) {
            toast.error("Withdrawal Already Made", {
              transition: Slide,
              theme: "dark",
            });
          }
        });
    } else {
      toast.error("Wrong Code");
    }
  };

  const notify = () => {
    toast.success("Withdrawal Process Cancelled");
    getUser(accessToken, userDetails._id);
    setTimeout(() => {
      toggleWithdrawalModal();
      toggleOTPModal();
    }, 3000);
  };

  const showAccurateFormat =
    transferStep === 1
      ? "Verification Code For VAT"
      : transferStep === 3
      ? "Code For Verification Of Transactions"
      : transferStep === 5
      ? "Code Verification For Foreign Transfer"
      : "";

  useEffect(() => {
    getUser(accessToken, _id);
  }, []);

  return (
    <>
      <section className="notification_modal otp_modal">
        <div className="notification_container">
          <div className="add_food_item_header">
            <h3 className="dashboard_header_text">Withdrawal</h3>
            <div className="go_back">
              <AiOutlineClose size={20} onClick={notify} />
            </div>
          </div>
          <div className="close_container">
            <p>{showAccurateFormat}</p>
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Enter Code"
                onChange={(e) => setcode(e.target.value)}
              />
            </div>
          </div>
          <div className="notification_flex">
            <button className="button" onClick={validateWithdrawal}>
              {loading ? (
                <ColorRing
                  visible={true}
                  height="45"
                  width="45"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["white", "white", "white", "white", "white"]}
                />
              ) : (
                "Enter Code"
              )}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OTPModal;
