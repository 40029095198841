import React from "react";
import { PageHero, Footer, Header, Body } from "../../../components";
import { businessEssentialChecking } from "../constant";

const BusinessEssentialChecking = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Business Essential Checking"}
          text={businessEssentialChecking}
        />
        <Body
          text={` A fundamental banking option designed to satisfy the essential
              financial requirements of small and medium-sized enterprises is
              Business Essential Checking. For business owners handling their
              daily affairs, this account offers simplicity, affordability, and
              convenience with essential features including basic checking,
              debit card access, online banking, and low fees. Businesses can
              ensure effective cash management and financial stability while
              concentrating on their core competencies by selecting Business
              Essential Checking.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default BusinessEssentialChecking;
