import React from "react";
import { useGlobalContext } from "../../../../context/context";
import { Link } from "react-router-dom";

function DashboardHeader({ title, text }) {
  const { userDetails } = useGlobalContext();

  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const { firstName, lastName, newUser } = user;
  return (
    <div className="overview_header">
      <div className="overview_header_welcome_sect">
        <h3>{title}, </h3>
        <p>{text}</p>
      </div>
      {newUser ? (
        ""
      ) : (
        <Link to={"/account-details"} className="user_prof">
          <h2>
            {firstName[0]}
            {lastName[0]}
          </h2>
        </Link>
      )}
    </div>
  );
}

export default DashboardHeader;
