import React, { useState } from "react";
import { MdAlternateEmail } from "react-icons/md";

import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Slide, toast } from "react-toastify";
import { useGlobalContext } from "../../context/context";

function ForgetPassword() {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const { baseUrl } = useGlobalContext();
  const { register, handleSubmit } = useForm();

  const handleForgetPassword = (data) => {
    setloading(true);
    axios
      .post(`${baseUrl}auth/forget`, data)
      .then((response) => {
        if (response.status === 200) {
          setloading(false);
          toast.success("Please Check Your Email For Reset Password Link", {
            transition: Slide,
          });
        }
      })
      .catch((err) => {
        setloading(false);
        if (err?.response?.data === "Wrong credentials") {
          toast.error("Email is not Registered.", {
            theme: "dark",
          });
        }
      });
  };

  return (
    <section className="login_wrapper">
      <div className="inner_login_wrapper">
        <h3>Forget Password</h3>
        <p>Enter Your Registered Email to Reset Password</p>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleForgetPassword(data))}
        >
          <p>EMAIL</p>
          <div className="input_wrapper">
            <MdAlternateEmail color="#888ea8" size={22} />
            <input
              type="email"
              placeholder="Enter Email"
              {...register("email")}
            />
          </div>
          <Button title={loading ? "Loading..." : "Forget Password"} />
        </form>
      </div>
    </section>
  );
}

export default ForgetPassword;
