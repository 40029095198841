import React, { useState } from "react";
import { CiMoneyBill } from "react-icons/ci";
import { ColorRing } from "react-loader-spinner";

function StepFour({ uploadedImage, register, handleImageChange, loader }) {
  return (
    <div className="deposit_step_two deposit_form_header">
      <div className="bank_deposit">
        <p>AMOUNT</p>
        <div className="input_wrapper">
          <CiMoneyBill color="#888ea8" size={22} />
          <input type="text" placeholder="AMOUNT" {...register("amount")} />
        </div>
        <input
          id="proof"
          name="images"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label htmlFor="proof" className="button">
          Upload Proof
        </label>
        {uploadedImage !== null && (
          <img src={uploadedImage} alt="" className="proof_image" />
        )}
        <button className="btn">
          {loader ? (
            <ColorRing
              visible={true}
              height="45"
              width="45"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["white", "white", "white", "white", "white"]}
            />
          ) : (
            "Make Deposit"
          )}
        </button>
      </div>
    </div>
  );
}

export default StepFour;
