import React from "react";
import loan from "../../asset/personal-loan.jpg";
import { Motion, MotionRight, MotionX } from "../Motion";
import Button from "../Button";

const Body = ({ text }) => {
  return (
    <div className="personal_loan_footer">
      <div className="personal_loan_footer_image_sect">
        <img src={loan} alt="" />
        <MotionRight>
          <p>{text}</p>
        </MotionRight>
      </div>
      <div className="home_why_choose_sect_item_one">
        <MotionRight>
          <h2>Our Values</h2>
        </MotionRight>
        <MotionRight>
          <h3>INTEGRITY AND HONESTY</h3>
        </MotionRight>
        <MotionRight>
          <h3>LOYALTY</h3>
        </MotionRight>
        <Motion>
          <h3>TEAM WORK</h3>
        </Motion>
        <Motion>
          <h3>COMMUNITY</h3>
        </Motion>
        <MotionX>
          <h3>ACCOUNTABILITY</h3>
        </MotionX>
        <MotionX>
          <h3>EXCELLENCE</h3>
        </MotionX>
        <MotionRight>
          <h3>CONFIDENTIALITY</h3>
        </MotionRight>
        <MotionRight>
          <h3>RELATIONSHIP</h3>
        </MotionRight>
        <Motion>
          <h2 className="personal_loan_footer_tags">Tags</h2>
        </Motion>
        <Motion>
          <div className="personal_loan_footer_btn_wrapper">
            <Button title={"Loans"} />
            <Button title={"Savings"} />
            <Button title={"Interest"} />
            <Button title={"Benefits"} />
            <Button title={"Travel"} />
          </div>
        </Motion>
      </div>
    </div>
  );
};

export default Body;
