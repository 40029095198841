import React from "react";
import { CiMoneyBill } from "react-icons/ci";
import { MdOutlinePlace } from "react-icons/md";

function StepOne({ register }) {
  return (
    <>
      <p>How much do you need?</p>
      <div className="input_wrapper">
        <CiMoneyBill color="#888ea8" size={22} />
        <input type="text" placeholder="Amount" {...register("amount")} />
      </div>
      <p>Monthly Income</p>
      <div className="input_wrapper">
        <CiMoneyBill color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="Monthly Income"
          {...register("monthlyIncome")}
        />
      </div>
      <p>Home Address</p>
      <div className="input_wrapper">
        <MdOutlinePlace color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="Home Address"
          {...register("homeAddress")}
        />
      </div>
      <p>City</p>
      <div className="input_wrapper">
        <MdOutlinePlace color="#888ea8" size={22} />
        <input type="text" placeholder="City" {...register("city")} />
      </div>
      <p>State</p>
      <div className="input_wrapper">
        <MdOutlinePlace color="#888ea8" size={22} />
        <input type="text" placeholder="State" {...register("state")} />
      </div>
    </>
  );
}

export default StepOne;
