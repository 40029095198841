import React from "react";
import { PageHero, Button, Header, Footer, Body2 } from "../../../components";
import loan from "../../../asset/personal-loan.jpg";
// import "./styles.css";

const LostOrStolenCard = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper online_banking_sect">
        <PageHero btnTitle={"Home Page"} />
        <Body2
          title={"Loast Or Stolen Card"}
          text={`Losing a card or having it stolen can be a stressful experience,
              but knowing what steps to take can help minimize potential risks
              and inconvenience. By taking prompt action and following these
              steps, you can minimize the impact of a lost or stolen card and
              protect yourself from potential fraud or unauthorized charges.
              Remember to keep your bank's contact information readily
              accessible in case of emergencies.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default LostOrStolenCard;
