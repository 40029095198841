import React from "react";
import { Button, Footer, Header, PageHero } from "../../../components";
import loan from "../../../asset/personal-loan.jpg";

function UltimateChecking() {
  return (
    <>
      <Header />
      <section className="personal_wrapper online_banking_sect">
        <PageHero btnTitle={"Home Page"} />
        <div className="personal_loan_footer">
          <div className="personal_loan_footer_image_sect">
            <img src={loan} alt="" />
          </div>
          <div className="home_why_choose_sect_item_one">
            <h1>Ultimate Checking</h1>
            <p>
              Ultimate checking in loans and investments plays a critical role
              in promoting transparency, trust, and integrity in the financial
              sector. By verifying information, assessing risks, and ensuring
              compliance with regulations, it enhances confidence among
              stakeholders and contributes to a more robust and resilient
              financial system.
            </p>
            <div className="personal_loan_footer_btn_wrapper">
              <Button title={"Loans"} />
              <Button title={"Savings"} />
              <Button title={"Interest"} />
              <Button title={"Benefits"} />
              <Button title={"Travel"} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default UltimateChecking;
