import React from "react";
import { PageHero, Button, Header, Footer, Body } from "../../../components";
import { overdraftProtectionAndSweeps } from "../constant";
import loan from "../../../asset/personal-loan.jpg";

const OverdraftProtectionAndSweeps = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Overdraft Protection And Sweeps"}
          text={overdraftProtectionAndSweeps}
        />
        <Body
          text={` Sweep services and overdraft protection are useful instruments for
              controlling expenses, cutting down on fees, and making the most of
              available funds. Through the efficient utilization of these
              services, both individuals and enterprises can improve their
              financial adaptability, minimize the likelihood of overdrafts, and
              optimize the returns on their idle capital. Make sure you read
              over the conditions and charges related to these services and
              select solutions that suit your tastes and financial objectives.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default OverdraftProtectionAndSweeps;
