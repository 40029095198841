import React from "react";
import { Body3, Footer, Header, PageHero } from "../../../components";

function IndividualRetirementAccount() {
  return (
    <>
      <Header />
      <section className="personal_wrapper online_banking_sect">
        <PageHero btnTitle={"Home Page"} />
        <Body3
          title={"Individual Retirement Account"}
          text={` On the other hand, a Roth IRA allows contributions to be made with
          after-tax dollars, meaning contributions are not tax-deductible.
          However, qualified withdrawals in retirement, including earnings,
          are tax-free. Roth IRAs can be advantageous for individuals
          expecting to be in a higher tax bracket during retirement or those
          seeking tax diversification in their retirement savings. With a traditional IRA, contributions may be tax-deductible, and earnings grow tax-deferred until withdrawal during retirement. However, withdrawals in retirement are subject to income tax. Traditional IRAs can be advantageous for individuals looking to lower their current taxable income while saving for retirement.`}
        />
      </section>
      <Footer />
    </>
  );
}

export default IndividualRetirementAccount;
