import React, { useState } from "react";
import "./styles.css";
import { MdMenu } from "react-icons/md";
import logo from "../../../../asset/logo.png";
import { ImHome2 } from "react-icons/im";
import { FiTarget, FiUser } from "react-icons/fi";
import { IoIosFlash } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";

function Navbar({ path }) {
  const navigate = useNavigate();

  const logOut = () => {
    sessionStorage.removeItem("accessToken");
    navigate("/auth/login");
  };

  return (
    <section className="navbar_wrapper">
      <div className="navbar_header_sect">
        <MdMenu size={28} cursor={"pointer"} />
        <img src={logo} alt="name" />
      </div>
      <div className="navbar_container">
        <div className="navbar_container_top_item">
          <Link to={"/dashboard-overview"} className="nav_tab">
            <ImHome2 />
            <p className={`${path === "/dashboard-overview" && "activebar"}`}>
              Home
            </p>
          </Link>
          <Link to={"/loan"} className="nav_tab">
            <FiTarget />
            <p className={`${path === "/loan" && "activebar"}`}>Loan</p>
          </Link>
          <Link to={"/investify"} className="nav_tab">
            <IoIosFlash />
            <p className={`${path === "/investify" && "activebar"}`}>Invest</p>
          </Link>
          <Link to={"/account-details"} className="nav_tab">
            <FiUser />
            <p className={`${path === "/account-details" && "activebar"}`}>
              Account
            </p>
          </Link>
        </div>
        <div>
          <Link className="nav_tab" onClick={logOut}>
            <AiOutlineLogout />
            <p>Logout</p>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
