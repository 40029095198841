import React from "react";
import { PageHero, Button, Header, Footer, Body2 } from "../../../components";
import loan from "../../../asset/personal-loan.jpg";

const WireTransfer = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper online_banking_sect">
        <PageHero />
        <Body2
          title={"Wire Transfer"}
          text={`Wire transfers are electronic transactions used to transfer funds
              from one bank account to another, both domestically and
              internationally. Overall, wire transfers offer a fast, reliable,
              and secure way to transfer funds between bank accounts, making
              them suitable for various purposes, including business
              transactions, large purchases, and international payments.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default WireTransfer;
