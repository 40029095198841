import React from "react";
import { Body, Footer, Header, PageHero } from "../../../components";
import { workingCapitalLoans } from "../contants";

const WorkingCapitalLoans = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Working Capital Loans"}
          text={workingCapitalLoans}
        />
        <Body
          text={`Working capital loans serve as invaluable tools for businesses
              seeking to manage cash flow, support operations, and pursue growth
              opportunities. By understanding the various types of working
              capital financing options, evaluating their suitability for your
              business needs, and selecting reputable lenders, you can leverage
              working capital loans effectively to drive success and resilience
              in your business endeavors.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default WorkingCapitalLoans;
