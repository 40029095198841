import React from "react";
import { CiMoneyBill } from "react-icons/ci";
import { MdOutlinePlace } from "react-icons/md";

function StepTwo({ register }) {
  return (
    <>
      <p>Loan Purpose</p>
      <div className="input_wrapper">
        <MdOutlinePlace color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="Loan Purpose"
          {...register("loanPurpose")}
        />
      </div>
      <p>Professional Status</p>
      <div className="input_wrapper">
        <CiMoneyBill color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="e.g Self Employed or Employed"
          {...register("professionalStatus")}
        />
      </div>
      <p>Professional Category</p>
      <div className="input_wrapper">
        <CiMoneyBill color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="e.g Engineer"
          {...register("professionalCategory")}
        />
      </div>
      <p>Next of Kin</p>
      <div className="input_wrapper">
        <CiMoneyBill color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="Next of Kin"
          {...register("nextOfKin")}
        />
      </div>
      <p>Phone Number</p>
      <div className="input_wrapper">
        <CiMoneyBill color="#888ea8" size={22} />
        <input type="text" placeholder="Phone" {...register("phone")} />
      </div>
    </>
  );
}

export default StepTwo;
