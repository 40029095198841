import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import { ToastContainer, toast } from "react-toastify";

import investify from "../../../../asset/investify.png";

const PendingLoanModal = ({ togglePendingLoanModal, loanStatus }) => {
  const notify = () => {
    if (loanStatus === "Pending") {
      toast.success("Please Contact Customer Care To Verify Loan Balance");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      togglePendingLoanModal();
    }
  };
  return (
    <>
      <section className="notification_modal loan_wrapper_modal">
        <div className="notification_container">
          <div className="add_food_item_header">
            <h3 className="dashboard_header_text"></h3>
            <div className="go_back">
              <AiOutlineClose size={20} onClick={notify} />
            </div>
          </div>
          <div className="close_container">
            <img src={investify} alt="" />
            <h3>
              {loanStatus === "true"
                ? "Congratulations! Your Loan has been Approved"
                : "Congratulations! Your Loan is processing"}
            </h3>
            <p>
              This is a significant step towards your goals. Wishing you every
              success as you embark on this new chapter.
            </p>
          </div>
          <div className="notification_flex">
            <button className="button" onClick={notify}>
              Verify Loan
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default PendingLoanModal;
