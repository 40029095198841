import React, { useState } from "react";
import "./styles.css";
import { IoMdClose } from "react-icons/io";

import { checkDepositStep } from "./helper-method";
import { useGlobalContext } from "../../../../../context/context";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

function DepositModal({ toggleDeposit }) {
  const [activeTab, setActiveTab] = useState(1);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const { userDetails, baseUrl, getAllDeposits } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { handleSubmit, register } = useForm();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const [formData, setFormData] = useState({
    images: [],
  });

  const { firstName, lastName, _id, email } = user;
  const handleActiveTab = (value) => {
    setActiveTab(value);
  };

  const handleDeposit = (data) => {
    const depositForm = new FormData();

    depositForm.append("amount", data.amount);
    depositForm.append("userId", _id);
    depositForm.append("name", `${firstName} ${lastName}`);
    depositForm.append("email", email);
    for (let i = 0; i < formData.images.length; i++) {
      depositForm.append("image", formData.images[i]);
    }
    if (activeTab === 4) {
      setLoader(true);
      axios
        .post(`${baseUrl}deposit`, depositForm, {
          headers: {
            token: accessToken,
          },
        })
        .then((data) => {
          if (data.status === 200) {
            setLoader(false);
            toast.success("Deposit Successful");
            getAllDeposits(accessToken, _id);
            setTimeout(() => {
              toggleDeposit();
            }, 3000);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, images: e.target.files });
    if (e.target.files && e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <section className="deposit_modal">
      <div className="deposit_modal_item_one" onClick={toggleDeposit}></div>
      <div className="deposit_modal_item_two">
        <div className="deposit_header">
          <IoMdClose color="#7913e5" size={28} onClick={toggleDeposit} />
        </div>
        <form
          className="deposit_form"
          onSubmit={handleSubmit((data) => handleDeposit(data))}
        >
          {checkDepositStep(
            activeTab,
            handleActiveTab,
            register,
            handleImageChange,
            uploadedImage,
            loader
          )}
        </form>
      </div>
    </section>
  );
}

export default DepositModal;
