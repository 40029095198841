import React from "react";
import "./styles.css";

const Button = ({ title, icon, className, action }) => {
  return (
    <button onClick={action} className={`btn ${className}`}>
      {title} {icon}
    </button>
  );
};

export default Button;
