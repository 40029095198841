import React from "react";
import { PageHero, Header, Footer, Body } from "../../../components";
import { businessSavingsAccount } from "../constant";

const BusinessSavingsAccount = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Business Savings Account"}
          text={businessSavingsAccount}
        />
        <Body
          text={`A Business Savings Account is a vital tool for businesses to
              preserve capital, earn interest, and achieve financial objectives.
              By leveraging the core features, competitive interest rates, and
              business benefits of Savings Accounts, businesses can optimize
              cash management, build financial resilience, and position
              themselves for long-term success. Choosing the right Business
              Savings Account aligned with the business's needs and objectives
              is essential for maximizing returns and driving financial growth.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default BusinessSavingsAccount;
