import React, { useState } from "react";
import "./styles.css";

import headerLogo from "../../asset/logo.png";
import Button from "../Button";
import { business, loans, personal, services } from "./constant";
import { Link } from "react-router-dom";
import { RiMenuLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const toggleMenu = () => {
    setVisibility(!visibility);
    setActiveTab(0);
  };

  const handleActive = (e) => {
    const id = e.target.id;
    if (id === "1") {
      setActiveTab(1);
    } else if (id === "2") {
      setActiveTab(2);
    } else if (id === "3") {
      setActiveTab(3);
    } else if (id === "4") {
      setActiveTab(4);
    }
  };

  return (
    <header className="header">
      <Link to={"/"} className="header_logo_sect">
        <img src={headerLogo} alt="header logo" />
      </Link>
      <nav className={`header_nav ${!visibility ? "close_nav" : ""}`}>
        <Link onClick={toggleMenu} className="home_nav_btn" to="/">
          Home
        </Link>
        <Link id={1} onClick={handleActive} className="loans_nav_btn" to="#">
          <div
            className={`nav_tabs ${
              activeTab === 1 ? "loans_nav" : "close_loans"
            }`}
          >
            {loans.map((loan, index) => (
              <Link onClick={toggleMenu} to={`/${loan.path}`} key={index}>
                {loan.name}
              </Link>
            ))}
          </div>{" "}
          Loans
        </Link>
        <Link onClick={handleActive} id={2} className="loans_nav_btn" to="#">
          Business
          <div
            className={`nav_tabs ${
              activeTab === 2 ? "loans_nav" : "close_loans"
            }`}
          >
            {business.map((business, index) => (
              <Link onClick={toggleMenu} key={index} to={`/${business.path}`}>
                {business.name}
              </Link>
            ))}
          </div>
        </Link>
        <Link onClick={handleActive} id={3} className="loans_nav_btn" to="#">
          Service
          <div
            className={`nav_tabs ${
              activeTab === 3 ? "loans_nav" : "close_loans"
            }`}
          >
            {services.map((business, index) => (
              <Link onClick={toggleMenu} key={index} to={`/${business.path}`}>
                {business.name}
              </Link>
            ))}
          </div>
        </Link>
        <Link to="/contact">Contact</Link>
        <Link onClick={handleActive} id={4} className="loans_nav_btn" to="#">
          Personal
          <div
            className={`nav_tabs ${
              activeTab === 4 ? "loans_nav" : "close_loans"
            }`}
          >
            {personal.map((business, index) => (
              <Link onClick={toggleMenu} key={index} to={`/${business.path}`}>
                {business.name}
              </Link>
            ))}
          </div>
        </Link>
        <Link className="mobile_btn" to={"/login"}>
          <Button title={"Sign In"} className={"sign_in"} />
        </Link>
        <Link className="mobile_btn" to={"/create-account"}>
          <Button title={"Create free account"} className={"sign_up"} />
        </Link>
      </nav>
      <div className="header_btn_sect">
        <Link to={"/login"}>
          <Button title={"Sign In"} className={"sign_in"} />
        </Link>
        <Link to={"/create-account"}>
          <Button title={"Create free account"} className={"sign_up"} />
        </Link>
      </div>
      {visibility ? (
        <AiOutlineClose size={30} color="white" onClick={toggleMenu} />
      ) : (
        <RiMenuLine size={30} color="white" onClick={toggleMenu} />
      )}
    </header>
  );
};

export default Header;
