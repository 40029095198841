export const heroText = `
You may get all the financial services you need in one place at Ogidi Finance. Welcome! We can help you whether you're trying to find profitable
  investment possibilities to increase your money or a loan to fund your
  dreams. With our platform, borrowers can easily connect with reliable
  lenders and investors may choose from a variety of options that are
  catered to their needs and financial objectives. Navigating the world of
  loans and investments has never been simpler thanks to our user-friendly
  design, strong security measures, and tailored suggestions. Participate
  with us now to confidently take charge of your financial destiny.
`;
