import { toast } from "react-toastify";
import { StepOne, StepTwo } from "./component";
import { ColorRing } from "react-loader-spinner";

export const checkCurrentStep = (step, register) => {
  return step === 1 ? (
    <StepOne register={register} />
  ) : (
    <StepTwo register={register} />
  );
};

export const checkText = (completedStep, loading, processing) => {
  return processing ? (
    "Validating..."
  ) : loading ? (
    <ColorRing
      visible={true}
      height="45"
      width="45"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={["white", "white", "white", "white", "white"]}
    />
  ) : completedStep === 1 ? (
    "Next Step"
  ) : completedStep === 2 ? (
    "Take Loan"
  ) : (
    ""
  );
};

export const checkSteps = (
  getValues,
  setCompletedStep,
  completedStep,
  validateRegistration,
  data
) => {
  const {
    homeAddress,
    state,
    city,
    amount,
    monthlyIncome,
    nextOfKin,
    proffessionalCategory,
    proffessionalStatus,
    phone,
    loanPurpose,
  } = getValues();
  if (
    homeAddress !== "" &&
    state !== "" &&
    city !== "" &&
    amount !== "" &&
    monthlyIncome !== "" &&
    completedStep === 1
  ) {
    setCompletedStep(2);
  }
  if (
    homeAddress === "" ||
    city === "" ||
    monthlyIncome === "" ||
    amount === "" ||
    state === ""
  ) {
    toast.error("All input field must be valid");
  }
  if (
    proffessionalCategory !== "" &&
    proffessionalStatus !== "" &&
    nextOfKin !== "" &&
    phone !== "" &&
    loanPurpose !== "" &&
    completedStep === 2
  ) {
    validateRegistration(data);
  }
  if (
    proffessionalCategory === "" ||
    proffessionalStatus === "" ||
    nextOfKin === "" ||
    phone === "" ||
    loanPurpose === ""
  ) {
    toast.error("All input field must be valid");
  }
};
