import React from "react";
import "./styles.css";
import { ImHome2 } from "react-icons/im";
import { FiTarget, FiUser } from "react-icons/fi";
import { IoIosFlash } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

function MobileNavbar() {
  const { pathname } = useLocation();

  return (
    <div className="mobile_dashboard_navbar">
      <Link to={"/dashboard-overview"}>
        <ImHome2
          size={23}
          color={
            pathname === "/dashboard-overview" ? "var(--pri-clr)" : "#a0aec0"
          }
        />
        <p
          className={`${
            pathname === "/dashboard-overview" ? "active_path" : ""
          }`}
        >
          Home
        </p>
      </Link>
      <Link to={"/loan"}>
        <FiTarget
          size={23}
          color={pathname === "/loan" ? "var(--pri-clr)" : "#a0aec0"}
        />
        <p className={`${pathname === "/loan" ? "active_path" : ""}`}>Loan</p>
      </Link>
      <Link to={"/investify"}>
        <IoIosFlash
          size={23}
          color={pathname === "/investify" ? "var(--pri-clr)" : "#a0aec0"}
        />
        <p className={`${pathname === "/investify" ? "active_path" : ""}`}>
          Invest
        </p>
      </Link>
      <Link to={"/account-details"}>
        <FiUser
          size={23}
          color={pathname === "/account-details" ? "var(--pri-clr)" : "#a0aec0"}
        />
        <p
          className={`${pathname === "/account-details" ? "active_path" : ""}`}
        >
          Account
        </p>
      </Link>
    </div>
  );
}

export default MobileNavbar;
