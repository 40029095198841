import React from "react";
import "./styles.css";
import { Body, Footer, Header, PageHero } from "../../../components";
import { personalIntro } from "../contants";

const PersonalLoans = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Personal Loans"}
          text={personalIntro}
        />
        <Body
          text={` The keys to your dream home are within reach — and we can help you
            get them with affordable rates and flexible terms catered to your
            unique situation. You’ll also receive personalized service from our
            local experts, as well as a quick decision.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default PersonalLoans;
