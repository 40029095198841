import React from "react";
// import "./styles.css";
import { Body, Button, Footer, Header, PageHero } from "../../../components";
import { homeMortgageLoans } from "../contants";
import loan from "../../../asset/personal-loan.jpg";

const HomeMortgageLoans = () => {
  return (
    <>
      <Header />
      <section className="personal_wrapper">
        <PageHero
          btnTitle={"Home Page"}
          headerTitle={"Home Mortgage Loans"}
          text={homeMortgageLoans}
        />
        <Body
          text={` Many people's aspirations to become homeowners are made possible
              by home mortgage loans; by being aware of the complexities of
              mortgage financing, borrowers can confidently navigate the process
              and obtain the best loan for their needs and financial situation;
              always seek advice from reliable sources and carefully weigh the
              long-term effects of homeownership.`}
        />
      </section>
      <Footer />
    </>
  );
};

export default HomeMortgageLoans;
