import React from "react";
import "./styles.css";
import Button from "../Button";
import { Motion, MotionRight, MotionX } from "../Motion";
import footerLogo from "../../asset/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_first_sect">
        <div className="footer_item_one">
          <Motion>
            <h3>Contacts</h3>
          </Motion>
          <MotionX>
            <p className="phone_number">+438249595945</p>
          </MotionX>
          <MotionRight>
            <a
              href="mailto:support@ogidifinance.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support@ogidifinance.com
            </a>
          </MotionRight>
          <img src={footerLogo} alt="" />
        </div>
        <div className="footer_item_two">
          <Motion>
            <h3>Company</h3>
          </Motion>
          <MotionX>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              About Us
            </a>
          </MotionX>
          <MotionX>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Working Loans
            </a>
          </MotionX>
          <MotionX>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Investing
            </a>
          </MotionX>
        </div>
        <div className="footer_item_three">
          <Motion>
            <h3>Quick Links</h3>
          </Motion>
          <Motion>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Business Account
            </a>
          </Motion>
          <Motion>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Lost Card
            </a>
          </Motion>
          <Motion>
            <a href="http://" target="_blank" rel="noopener noreferrer">
              Health Savings
            </a>
          </Motion>
        </div>
        <div className="footer_item_four">
          <MotionRight>
            <h3>Our Newsletter</h3>
          </MotionRight>
          <MotionRight>
            <p>
              Subscribe to our newsletter and we will inform you about latest
              updates and offers
            </p>
          </MotionRight>
          <input type="text" />
          <MotionRight>
            <Button title={"Send"} className="sign_up" />
          </MotionRight>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
