import React, { useEffect, useState } from "react";
import { client } from "../../../../../../utils/client";
import { ColorRing } from "react-loader-spinner";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

function StepThree({ handleActiveTab }) {
  const [addresses, setAddress] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAddress = (address) => {
    copy(address?.address);
    toast.success("Wallet Address Copied");
  };

  const getAddresses = () => {
    setLoading(true);
    client
      .fetch('*[_type == "addresses"]')
      .then((data) => {
        setAddress(data);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAddresses();
  }, []);
  return (
    <div className="deposit_step_two deposit_form_header">
      <div className="bank_deposit">
        {loading ? (
          <div className="loader_wrapper">
            <ColorRing
              visible={true}
              height="45"
              width="45"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#122231", "#122231", "#122231", "#122231", "#122231"]}
            />
          </div>
        ) : (
          addresses.map((address, index) => (
            <div
              key={index}
              className="history_sect wallet_history_sect"
              onClick={() => getAddress(address)}
            >
              <div>
                <h3>{address?.walletName}</h3>
                <p className="date_text">{address?.address}</p>
              </div>
            </div>
          ))
        )}
        <button className="btn" onClick={() => handleActiveTab(4)}>
          Next Step
        </button>
      </div>
    </div>
  );
}

export default StepThree;
