import React from "react";
import "./styles.css";
import Button from "../Button";
import { Motion, MotionX } from "../Motion";
import { Link } from "react-router-dom";

const PageHero = ({ headerTitle, btnTitle, text }) => {
  return (
    <div className="home_hero_sect">
      <MotionX>
        <h1>{headerTitle}</h1>
      </MotionX>
      <Motion>
        <p>{text}</p>
      </Motion>
      <Motion>
        <Link to={btnTitle == "Home Page" ? "/" : "/register"}>
          <Button title={btnTitle} className={"sign_up"} />
        </Link>
      </Motion>
    </div>
  );
};

export default PageHero;
