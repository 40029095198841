import React, { useEffect, useState } from "react";
import "./styles.css";
import { FiLock, FiUser } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loading from "../../components/HOC";

function Login() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [almost, setAlmost] = useState(false);
  const [wait, setwait] = useState(false);

  const toggleVisible = () => setVisible(!visible);

  const { baseUrl, getAllWithdrawalss } = useGlobalContext();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = (data) => {
    setLoading(true);
    setTimeout(() => {
      setwait(true);
    }, 15000);

    setTimeout(() => {
      setAlmost(true);
    }, 30000);
    axios
      .post(`${baseUrl}auth/login`, data)
      .then((response) => {
        setLoading(false);
        setwait(false);
        setAlmost(false);
        sessionStorage.setItem("user", JSON.stringify(response.data));
        sessionStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.accessToken)
        );
        toast.success("Login Verified");

        setTimeout(() => {
          navigate("/dashboard-overview");
        }, 2000);
      })
      .catch((error) => {
        if (error?.response?.data === "Wrong credentials") {
          toast.error("Wrong Credentials");
        }
        setLoading(false);
        setwait(false);
        setAlmost(false);
      });
  };

  useEffect(() => {
    getAllWithdrawalss();
  });

  return (
    <section className="login_wrapper">
      <div className="inner_login_wrapper">
        <h3>Sign In</h3>
        <p>Log in to your account to continue</p>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleLogin(data))}
        >
          <p>EMAIL</p>
          <div className="input_wrapper">
            <FiUser color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
            <input
              type="email"
              placeholder="Email"
              {...register("email", { required: "Email is required" })}
            />
          </div>
          <span className="err">{errors?.email?.message}</span>
          <div className="create_account_text_wrapper">
            <p>PASSWORD</p>
            <Link to={"/create-account"}>Create An Account</Link>
          </div>
          <div className="input_wrapper">
            <FiLock color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
            <input
              type={visible ? "text" : "password"}
              placeholder="Your Password"
              className="password"
              {...register("password", { required: "Password is required" })}
            />
            {visible ? (
              <MdVisibility
                color="#888ea8"
                size={22}
                cursor="pointer"
                onClick={toggleVisible}
              />
            ) : (
              <MdVisibilityOff
                color="#888ea8"
                size={22}
                cursor="pointer"
                onClick={toggleVisible}
              />
            )}
          </div>
          <span className="err">{errors?.password?.message}</span>
          <div className="create_account_text_wrapper forget_password_text_wrapper">
            <p></p>
            <Link to={"/forget-password"}>Forget Password</Link>
          </div>
          <button className="btn">
            {almost
              ? "Almost there!"
              : wait
              ? "Please Wait!"
              : loading
              ? "Loading..."
              : "Log In"}
          </button>
        </form>
      </div>
    </section>
  );
}

export default Loading(Login);
