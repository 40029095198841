import React from "react";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import Select from "react-select";
import { useGlobalContext } from "../../../../context/context";

function StepThree({ register }) {
  const { changeHandler, options, value } = useGlobalContext();
  return (
    <>
      <p>Country </p>
      <Select options={options} value={value} onChange={changeHandler} />
      <p>Address</p>
      <div className="input_wrapper">
        <MdOutlinePhone color="#888ea8" size={22} />
        <input type="text" placeholder="Address* " {...register("address")} />
      </div>
      <p>Gender</p>
      <div className="input_wrapper">
        <MdOutlineEmail color="#888ea8" size={22} />
        <input type="text" placeholder="Gender* " {...register("gender")} />
      </div>
      <p>Marital Status</p>
      <div className="input_wrapper">
        <CiCalendarDate color="#888ea8" size={22} />
        <input
          type="text"
          placeholder="Marital Status* "
          {...register("maritalstatus")}
        />
      </div>
    </>
  );
}

export default StepThree;
