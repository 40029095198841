import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CiMoneyBill } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { checkWithdrawalInputsValid } from "./helper-method";
import { BsBank } from "react-icons/bs";
import { MdNumbers } from "react-icons/md";

import { useGlobalContext } from "../../../../../context/context";
import KYCModal from "../KYCModal";
import OTPModal from "../OTPModal";

function WithdrawalModal({
  toggleWithdrawModal,
  totalAmount,
  loanHistory,
  withdrawalPath,
  method,
}) {
  const [kycModal, setKycModal] = useState(false);
  const [otpModal, setotpModal] = useState(false);

  const { kyc, userDetails } = useGlobalContext();
  const { register, handleSubmit, watch, getValues } = useForm();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const kycDetails = JSON.parse(sessionStorage.getItem("kyc")) || kyc;
  const { transferStep } = user;

  const handleWithdrawal = () => {
    checkWithdrawalInputsValid(
      watch,
      kycDetails,
      setKycModal,
      totalAmount,
      loanHistory?.status,
      toggleotpModal,
      transferStep
    );
  };

  const toggleKYCModal = () => {
    setKycModal(!kycModal);
    window.location.reload();
  };

  const toggleotpModal = () => {
    setotpModal(!otpModal);
  };

  return (
    <section className="deposit_modal">
      <div
        className="deposit_modal_item_one"
        onClick={toggleWithdrawModal}
      ></div>
      <div className="deposit_modal_item_two withdraw_modal_item_two">
        <div className="deposit_header">
          <IoMdClose color="#7913e5" size={28} onClick={toggleWithdrawModal} />
        </div>
        <form
          onSubmit={handleSubmit((data) => handleWithdrawal(data))}
          className="deposit_form"
        >
          <div className="deposit_step_two deposit_form_header">
            <div className="bank_deposit">
              <p>Amount</p>
              <div className="input_wrapper">
                <CiMoneyBill color="#888ea8" size={22} />
                <input
                  type="text"
                  placeholder="AMOUNT"
                  {...register("amount")}
                />
              </div>
              {method === "bank transfer" ? (
                <>
                  <p>Account Name</p>
                  <div className="input_wrapper">
                    <CiMoneyBill color="#888ea8" size={22} />
                    <input
                      type="text"
                      placeholder="Account Name"
                      {...register("accountName")}
                    />
                  </div>
                  <p>Account Number</p>
                  <div className="input_wrapper">
                    <MdNumbers color="#888ea8" size={22} />
                    <input
                      type="text"
                      placeholder="Account Number"
                      {...register("accountNumber")}
                    />
                  </div>
                  <p>Bank Name</p>
                  <div className="input_wrapper">
                    <BsBank color="#888ea8" size={22} />
                    <input
                      type="text"
                      placeholder="Bank Name"
                      {...register("bankName")}
                    />
                  </div>
                  <p>Account Type</p>
                  <div className="input_wrapper">
                    <CiMoneyBill color="#888ea8" size={22} />
                    <input
                      type="text"
                      placeholder="Account Type"
                      {...register("accountType")}
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>Email</p>
                  <div className="input_wrapper">
                    <CiMoneyBill color="#888ea8" size={22} />
                    <input
                      className="diffrent"
                      type="text"
                      placeholder={`Your ${method} Email`}
                      {...register("email")}
                    />
                  </div>
                </>
              )}
              <button className="btn">Withdraw Funds</button>
            </div>
          </div>
        </form>
      </div>
      {kycModal && (
        <KYCModal toggleKYCModal={toggleKYCModal} setKYCModal={setKycModal} />
      )}
      {otpModal && (
        <OTPModal
          toggleOTPModal={toggleotpModal}
          toggleWithdrawalModal={toggleWithdrawModal}
          getValues={getValues}
          withdrawalPath={withdrawalPath}
          method={method}
        />
      )}
    </section>
  );
}

export default WithdrawalModal;
