import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import { DashboardHeader } from "../../components";
import historyImage from "../../../../asset/history Image.png";

import {
  AdvancedRealTimeChart,
  CryptoCurrencyMarket,
} from "react-ts-tradingview-widgets";
import { DepositModal, WithdrawalModal } from "../../components/Modal";
import { useGlobalContext } from "../../../../context/context";
import moment from "moment";
import { CgUnavailable } from "react-icons/cg";

function Invest() {
  const [depositModal, setdepositModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const deposits = JSON.parse(sessionStorage.getItem("deposits"));
  const investtransfers = JSON.parse(sessionStorage.getItem("investwithdraw"));

  const {
    userDetails,
    getUser,
    moneyFormatter,
    geKYCDetails,
    depositHistory,
    getAllDeposits,
  } = useGlobalContext();
  const user = JSON.parse(sessionStorage.getItem("user")) || userDetails;
  const { _id, country, profit, investStatus } = user;
  const { symbol } = country;
  const totalAmount = deposits + profit - investtransfers;

  const toggleDepositModal = () => {
    setdepositModal(!depositModal);
  };

  const toggleWithdrawModal = () => setWithdrawModal(!withdrawModal);

  useEffect(() => {
    getUser(accessToken, _id);
    geKYCDetails(accessToken, _id);
    getAllDeposits(accessToken, _id);
  }, []);
  return (
    <Fragment>
      <section className="invest_wrapper">
        <DashboardHeader title={"Investify"} />
        <div className="loan_total_balance_sect">
          <div className="invest_total_sect history_sect">
            <p className="statement_text">TOTAL BALANCE</p>
            <h1 style={{ color: "#7913e5" }}>
              {symbol}
              {totalAmount}
            </h1>
            <button className="button" onClick={toggleWithdrawModal}>
              Withdraw
            </button>
          </div>
          <div className="history_sect per_annum_sect">
            <p className="statement_text">EXPECTED ROI</p>
            <h3>Up to 50%</h3>
            <p className="per_annum">per annum</p>
          </div>
        </div>
        {depositHistory.length > 0 ? (
          depositHistory.map((deposit, index) => {
            const { status, amount, createdAt } = deposit;
            return (
              <div key={index} className="history_sect">
                <div className="history_sect_item" id="history_sect_item">
                  <div>
                    <img src={historyImage} alt="historyimage" />
                    <div>
                      <p className="statement_text">
                        {status === "Pending"
                          ? "Pending"
                          : status === "true"
                          ? "Approved"
                          : "Failed"}
                      </p>
                      <p className="date_text">
                        {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </p>
                    </div>
                  </div>

                  <p className="date_text">
                    {symbol}
                    {moneyFormatter.format(amount)}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="history_sect">
            <div className="history_sect_item empty_wrapper">
              <CgUnavailable size={50} color="#e2e8f0" />
            </div>
          </div>
        )}
        {investStatus === "false" ? (
          <>
            <div className="loan_total_balance_sect">
              <div className="invest_info_sect history_sect">
                <h2>Start Investing!</h2>
                <p>
                  Start investing in verified opportunities. Let's help you get
                  started.
                </p>
                <button className="button" onClick={toggleDepositModal}>
                  Invest now
                </button>
              </div>
              <div className="loan_get_started_wrapper">
                <p className="date_text">INVESTIFY</p>
                <div></div>
              </div>
            </div>
            <div className="accumulation_sect">
              <div>
                <div>
                  <p>Basic Plan</p>
                  <h2>$100 - $999</h2>
                  <button className="button" onClick={toggleDepositModal}>
                    Invest Now
                  </button>
                </div>
              </div>
              <div>
                <div>
                  <p>Gold Plan</p>
                  <h2>$1,000 - $4,999</h2>
                  <button className="button" onClick={toggleDepositModal}>
                    Invest Now
                  </button>
                </div>
              </div>
              <div className="investment_wrapper">
                <div>
                  <p>Platinum Plan</p>
                  <h2>$10,000 - $50,000</h2>
                  <button className="button" onClick={toggleDepositModal}>
                    Invest Now
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="history_sect chart_wrapper">
            <AdvancedRealTimeChart width={"100%"} />
            <CryptoCurrencyMarket width={"100%"} />
          </div>
        )}
      </section>
      {depositModal && <DepositModal toggleDeposit={toggleDepositModal} />}
      {withdrawModal && (
        <WithdrawalModal
          toggleWithdrawModal={toggleWithdrawModal}
          totalAmount={totalAmount}
          withdrawalPath={"invest"}
        />
      )}
    </Fragment>
  );
}

export default Invest;
