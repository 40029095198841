import React, { useEffect, useState } from "react";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import { VscTypeHierarchySuper } from "react-icons/vsc";
import { MdNumbers } from "react-icons/md";
import { client } from "../../../../../../utils/client";
import { ColorRing } from "react-loader-spinner";

function StepTwo({ handleActiveTab }) {
  const [bankdetails, setbankDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    client
      .fetch('*[_type == "bankdetails"]')
      .then((data) => {
        setbankDetails(data[0]);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
      });
  }, []);

  const { accountName, accountNumber, bankName, bankType } = bankdetails || {};

  return (
    <div className="deposit_step_two deposit_form_header">
      <div className="bank_deposit">
        {loading ? (
          <div className="loader_wrapper">
            <ColorRing
              visible={true}
              height="45"
              width="45"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#122231", "#122231", "#122231", "#122231", "#122231"]}
            />
          </div>
        ) : (
          <>
            <div className="history_sect">
              <BsBank size={28} color="var(--pri-clr)" />
              <div>
                <h3>{bankName}</h3>
                <p className="date_text">Bank Name</p>
              </div>
            </div>
            <div className="history_sect">
              <MdDriveFileRenameOutline size={28} color="var(--pri-clr)" />
              <div>
                <h3>{accountName}</h3>
                <p className="date_text">Account Name</p>
              </div>
            </div>
            <div className="history_sect">
              <VscTypeHierarchySuper size={28} color="var(--pri-clr)" />
              <div>
                <h3>{bankType}</h3>
                <p className="date_text">Account Type</p>
              </div>
            </div>
            <div className="history_sect">
              <MdNumbers size={28} color="var(--pri-clr)" />
              <div>
                <h3>{accountNumber}</h3>
                <p className="date_text">Account Number</p>
              </div>
            </div>
          </>
        )}
        <button className="btn" onClick={() => handleActiveTab(4)}>
          Next Step
        </button>
      </div>
    </div>
  );
}

export default StepTwo;
