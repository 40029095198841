import { toast } from "react-toastify";

export const checkWithdrawalInputsValid = (
  watch,
  kycDetails,
  setKyc,
  totalAmount,
  loanStatus,
  toggleOtpModal,
  transferStep
) => {
  const { accountType, accountNumber, amount, bankName, accountName } = watch();
  if (
    kycDetails?.length === 0 &&
    accountName !== "" &&
    accountNumber !== "" &&
    amount !== "" &&
    accountType !== "" &&
    bankName !== ""
  ) {
    toast.error("Please you need to verify KYC.");
    setTimeout(() => {
      setKyc(true);
    }, 2000);
  } else if (
    kycDetails?.status === false &&
    accountName !== "" &&
    accountNumber !== "" &&
    amount !== "" &&
    accountType !== "" &&
    bankName !== ""
  ) {
    toast.error(
      "Please Wait, Your KYC Verification Is processing Or You Can Reach Out To Customer Care For Support"
    );
  } else if (loanStatus === "Pending") {
    toast.error("Your Loan Balance Has Not Been Approved");
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  } else if (
    totalAmount < amount &&
    accountName !== "" &&
    accountNumber !== "" &&
    amount !== "" &&
    accountType !== "" &&
    bankName !== ""
  ) {
    toast.error("Insuficient Balance");
  } else if (
    accountName === "" ||
    accountNumber === "" ||
    amount === "" ||
    accountType === "" ||
    bankName === ""
  ) {
    toast.error("Please all input field must be valid.");
  } else if (transferStep % 2 === 1) {
    toggleOtpModal();
  } else {
    toast.success("Reach out to Customer to verify withdrawal");
  }
};
