import React, { useState } from "react";
import { FiLock } from "react-icons/fi";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Button } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import { useForm } from "react-hook-form";
import { Slide, toast } from "react-toastify";
import axios from "axios";

function ResetPassword() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => setVisible(!visible);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  const { baseUrl } = useGlobalContext();
  const { handleSubmit, register } = useForm();
  const handleResetPassword = (data) => {
    setloading(true);
    data.resetToken = id;

    axios
      .post(`${baseUrl}auth/reset`, data)
      .then((response) => {
        setloading(false);
        toast.success("Password Changed Successfully");
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      })
      .catch((error) => {
        setloading(false);
        if (error?.response?.data === "Passwords do not match") {
          toast.error("Password Does not Matched", {
            transition: Slide,
            theme: "dark",
          });
        } else if (
          error.response.data === "Invalid token or token has expired"
        ) {
          toast.error("Invalid token or token has expired", {
            transition: Slide,
            theme: "dark",
          });
        } else {
          toast.error("Token Expired", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };
  return (
    <section className="login_wrapper">
      <div className="inner_login_wrapper">
        <h3>Reset Password</h3>
        <p>Log in to your account to continue</p>
        <form
          className="login_form"
          onSubmit={handleSubmit((data) => handleResetPassword(data))}
        >
          <p>PASSWORD</p>
          <div className="input_wrapper">
            <FiLock color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
            <input
              type={visible ? "text" : "password"}
              placeholder="Your Password"
              className="password"
              {...register("password")}
            />
            {visible ? (
              <MdVisibility
                color="#888ea8"
                size={22}
                cursor="pointer"
                onClick={toggleVisible}
              />
            ) : (
              <MdVisibilityOff
                color="#888ea8"
                size={22}
                cursor="pointer"
                onClick={toggleVisible}
              />
            )}
          </div>
          <p>CONFIRM PASSWORD</p>
          <div className="input_wrapper">
            <FiLock color="#888ea8" size={22} fill="rgba(0, 23, 55, 0.08)" />
            <input
              type={visible ? "text" : "password"}
              placeholder="Confirm Password"
              className="confirmpassword"
              {...register("confirmpassword")}
            />
            {visible ? (
              <MdVisibility
                color="#888ea8"
                size={22}
                cursor="pointer"
                onClick={toggleVisible}
              />
            ) : (
              <MdVisibilityOff
                color="#888ea8"
                size={22}
                cursor="pointer"
                onClick={toggleVisible}
              />
            )}
          </div>
          <Button title={loading ? "Loading..." : "Reset Password"} />
        </form>
      </div>
    </section>
  );
}

export default ResetPassword;
